import React, { FC } from "react";
import { Player, Role } from "./Game";
import "./PlayerChip.css";

type Props = {
  player: Player;
  showRole?: boolean;
  votingPlayers?: Player[];
  fadeDeadPlayers?: boolean;
};

const PlayerChip: FC<Props> = ({
  player,
  showRole = false,
  votingPlayers = [],
  fadeDeadPlayers = true,
}) => {
  const roleImgUrl = imgUrlForRole(player.role);
  const imgUrl = showRole && roleImgUrl ? roleImgUrl : playerAvatarUrl(player);
  const voteMarkers = (
    <div className="VoteMarkerContainer">
      {votingPlayers.map((vp) => {
        const confirmed = vp.votes[vp.votes.length - 1].confirmed;
        return (
          <div key={vp.id}>
            <img
              alt=""
              className={confirmed ? "VotedMarker" : "VotedMarker UnconfirmedVote"}
              src={playerAvatarUrl(vp)}
            ></img>
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={player.alive || !fadeDeadPlayers ? "PlayerChip" : "PlayerChip DeadPlayer"}>
      <img alt="" className="PlayerChipImage" src={imgUrl}></img>
      <div className="PlayerNameContainer">
        <div>{player.name}</div>
        {showRole && <div>({player.role})</div>}
        {votingPlayers.length > 0 && voteMarkers}
      </div>
    </div>
  );
};

function playerAvatarUrl(player: Player) {
  return "https://avatars.dicebear.com/api/gridy/" + player.id + ".svg";
}

function imgUrlForRole(role: Role): string | undefined {
  switch (role) {
    case Role.WEREWOLF:
      return "/wolf_avatar.png";
    default:
      return undefined;
  }
}

export default PlayerChip;
