import { AppBar, Button, CircularProgress, Container, Toolbar } from "@material-ui/core";
import "fontsource-roboto";
import Pusher from "pusher-js";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BASE_URL, PUSHER_CLUSTER, PUSHER_PUBLIC_KEY } from "./Config";
import "./Game.css";
import GameContent from "./GameContent";
import { Settings } from "./GameSettings";
import { sendRequestAndForget } from "./Utils";

const axios = require("axios").default;

export enum TurnType {
  LOBBY = "LOBBY",
  LOBBY_READY_TO_START = "LOBBY_READY_TO_START",
  SHOW_ROLES = "SHOW_ROLES",
  NIGHT = "NIGHT",
  DAY = "DAY",
  END = "END",
}

export enum Role {
  WEREWOLF = "WEREWOLF",
  VILLAGER = "VILLAGER",
  SEER = "SEER",
  DOCTOR = "DOCTOR",
}

type GameProps = {
  gameId: string;
  playerId: string;
};

export type Game = {
  id: string;
  version: number;
  settings: Settings;
  turnNumber: number;
  turnType: TurnType;
  turnStartTimestamp: number;
  player: Player;
  players: Player[];
  playersById: Map<string, Player>;
  deathsFromLastTurn: string[];
  host: boolean;
  winningRoles: Role[];
};

export type Vote = {
  turn: number;
  confirmed: boolean;
  votingPlayer: string;
  playerVotedFor: string;
};

export type Player = {
  id: string;
  name: string;
  role: Role;
  roleAcknowledged: boolean;
  alive: boolean;
  votes: Vote[];
};

type State = {
  game: Game | null;
  millisUntilNextTurn: number;
  lastTurnEndRequested: number;
};

class PlayGame extends React.Component<RouteComponentProps<GameProps>, State> {
  state: State = {
    game: null,
    millisUntilNextTurn: 0,
    lastTurnEndRequested: 0,
  };

  gameId: string = this.props.match.params.gameId;
  playerId: string = this.props.match.params.playerId;
  pusher: Pusher | undefined;

  componentDidMount() {
    setInterval(() => {
      if (this.state.game === null) {
        return;
      }
      const game = this.state.game;
      if (
        game.turnNumber <= this.state.lastTurnEndRequested ||
        ![TurnType.DAY, TurnType.NIGHT].includes(game.turnType)
      ) {
        return;
      }
      const turnSecs =
        game.turnType === TurnType.DAY
          ? game.settings.dayLengthSecs
          : game.settings.nightLengthSecs;
      this.setState({
        millisUntilNextTurn: game.turnStartTimestamp + turnSecs * 1000 - Date.now(),
      });
      if (
        (this.state.millisUntilNextTurn < 0 && this.state.game.host) ||
        this.state.millisUntilNextTurn < -2000
      ) {
        sendRequestAndForget(this.gameId, "endturn/" + this.state.game.turnNumber);
        this.setState({
          lastTurnEndRequested: this.state.game.turnNumber,
        });
      }
    }, 300);
  }

  render() {
    if (this.state.game === null) {
      this.getGameState();
      return <CircularProgress />;
    }

    return (
      <div className="GameContainer">
        <AppBar position="static">
          <Toolbar className="Toolbar">
            <div className="GameIdText">
              Game ID: <b>{this.gameId}</b>
            </div>
            <div className="TurnText">{PlayGame.turnText(this.state.game.turnType)}</div>
            <div className="QuitButtonContainer">
              <Button color="inherit" href="/">
                Quit
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <div className="GameContentContainer">
          <Container maxWidth="xs" className="FullHeight">
            <GameContent
              game={this.state.game}
              gameId={this.gameId}
              playerId={this.playerId}
              millisUntilNextTurn={this.state.millisUntilNextTurn}
            />
          </Container>
        </div>
      </div>
    );
  }

  private static turnText(turnType: TurnType) {
    switch (turnType) {
      case TurnType.DAY:
        return "Day";
      case TurnType.NIGHT:
        return "Night";
      default:
        return "";
    }
  }

  async updateGameState(newState: any) {
    if (this.state.game != null && newState.version <= this.state.game.version) {
      console.log("Ignoring older version");
      return;
    }
    const playersById: Map<string, any> = new Map(newState.players.map((p: Player) => [p.id, p]));
    const player: Player = playersById.get(this.playerId)!;

    if (!this.state.game && player.alive) {
      // First game info received, so subscribe.
      console.log("Connecting Pusher");
      this.pusher = new Pusher(PUSHER_PUBLIC_KEY, {
        cluster: PUSHER_CLUSTER,
      });
      console.log("Subscribing to updates");
      const channel = this.pusher.subscribe(this.gameId);
      channel.bind("game-update", (data: any) => {
        console.log("Pusher received");
        this.updateGameState(data);
      });
    }
    if ((!player.alive || newState.turnType === TurnType.END) && this.pusher) {
      // Save Pusher connections and disconnect on death / end.
      console.log("Disconnecting Pusher");
      this.pusher.disconnect();
    }

    this.setState({
      game: {
        id: this.props.match.params.gameId,
        version: newState.version,
        settings: newState.settings,
        turnNumber: newState.turnNumber,
        turnType: newState.turnType,
        turnStartTimestamp: newState.turnStartTimestamp,
        player: player,
        players: newState.players,
        playersById,
        deathsFromLastTurn: newState.deathsFromLastTurn,
        host: newState.hostPlayerId === this.playerId,
        winningRoles: newState.winningRoles,
      },
    });
  }

  async getGameState() {
    try {
      const response = await axios.get(BASE_URL + "api/gamestate/" + this.gameId);
      this.updateGameState(response.data);
    } catch (error) {
      console.error(error);
    }
  }
}

export default PlayGame;
