import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { Game, Player, Role } from "./Game";
import PlayerChip from "./PlayerChip";
import "./ShowRole.css";
import { sendRequestAndForget } from "./Utils";
import "./VoteList.css";

type Props = {
  gameId: string;
  playerId: string;
  game: Game;
};

export default function ShowRole(props: Props) {
  const examplePlayer1: Player = {
    id: "PL1",
    name: "Player1",
    role: Role.VILLAGER,
    roleAcknowledged: true,
    alive: true,
    votes: [{ turn: 1, confirmed: true, votingPlayer: "PL1", playerVotedFor: "PL2" }],
  };

  const examplePlayer2: Player = {
    id: "PL2",
    name: "Player2",
    role: Role.VILLAGER,
    roleAcknowledged: true,
    alive: true,
    votes: [{ turn: 1, confirmed: false, votingPlayer: "PL2", playerVotedFor: "PL3" }],
  };

  return (
    <div className="ShowRoleContent">
      <div className="AccordionContainer">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>Your role (click to show)</AccordionSummary>
          <AccordionDetails>
            <div className="RoleDescription">
              <Typography variant="h6">{roleText(props.game.player.role)}</Typography>
              <div>{roleDescription(props.game.player.role)}</div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="GameDescriptionHeading">
        <b>Goal</b>
      </div>
      <div>
        Werewolves kill off the villagers during the night and villagers kill suspected werewolves
        during the day. The game ends when either all werewolves are dead or the number of
        werewolves/villagers is the same.
      </div>

      <div className="GameDescriptionHeading">
        <b>Voting</b>
      </div>
      <div>
        Tap on a player to indicate to others that you're going to vote for them. This can be
        changed until you confirm your vote. In the example below, Player2 has an unconfirmed vote
        for Player1 and Player1 has a confirmed vote for Player2 (unconfirmed votes are opaque).
      </div>
      <div style={{ width: "100%" }}>
        <div className="PlayerList">
          <PlayerChip player={examplePlayer1} votingPlayers={[examplePlayer2]} />
          <PlayerChip player={examplePlayer2} votingPlayers={[examplePlayer1]} />
        </div>
      </div>

      <div className="GameDescriptionHeading">
        <b>Tips</b>
      </div>
      <div>
        When playing in person, sit in a circle. During the day, face inwards so that everyone can
        see each other. During the night, face outwards so that no one can see who uses their
        device.
      </div>

      <div className="ReadyButtonContainer">
        {props.game.player.roleAcknowledged ? (
          <>Waiting for other players ...</>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => ackRole(props.gameId, props.playerId)}
          >
            Ready
          </Button>
        )}
      </div>
    </div>
  );
}

function roleText(role: Role): string {
  switch (role) {
    case Role.VILLAGER:
      return "Villager";
    case Role.WEREWOLF:
      return "Werewolf";
    case Role.SEER:
      return "Seer";
    case Role.DOCTOR:
      return "Doctor";
    default:
      throw new Error("Unexpected role");
  }
}

function roleDescription(role: Role): string {
  switch (role) {
    case Role.VILLAGER:
      return "Find the werewolves and kill them off during the day.";
    case Role.WEREWOLF:
      return "Kill villagers during the night and keep your identity hidden.";
    case Role.SEER:
      return "Identify werewolves during the night and try to get them killed during the day.";
    case Role.DOCTOR:
      return "Protect a player each night.";
    default:
      throw new Error("Unexpected role");
  }
}

async function ackRole(gameId: string, playerId: string) {
  sendRequestAndForget(gameId, "ackrole/" + playerId);
}
