import React from "react";
import { Game, Player, Role, Vote } from "./Game";
import "./Night.css";
import { voteForTurn } from "./Utils";
import VoteList from "./VoteList";

type Props = {
  game: Game;
};

export default function Night(props: Props) {
  switch (props.game.player.role) {
    case Role.VILLAGER:
      return sleeping();
    case Role.WEREWOLF:
      return werewolf(props.game);
    case Role.SEER:
      return seer(props.game);
    case Role.DOCTOR:
      return doctor(props.game);
    default:
      throw new Error("Unexpected role");
  }
}

function sleeping() {
  return <div className="SleepingMessage">Sleeping through the night ...</div>;
}

function werewolf(game: Game) {
  return (
    <div>
      <VoteList
        game={game}
        votingPlayers={game.players.filter((p) => p.role === Role.WEREWOLF)}
        canBeVoted={game.players.filter((p) => p.alive && p.role !== Role.WEREWOLF)}
        showMatchingRoles={true}
      />
    </div>
  );
}

function seer(game: Game) {
  const playerVote: Vote | null = voteForTurn(game.player, game.turnNumber);
  return (
    <div>
      {playerVote === null || !playerVote.confirmed ? (
        <VoteList
          game={game}
          votingPlayers={[game.player]}
          canBeVoted={game.players}
          showMatchingRoles={false}
        />
      ) : (
        seerResult(game.playersById.get(playerVote.playerVotedFor)!)
      )}
    </div>
  );
}

function seerResult(player: Player) {
  return (
    <div className="VotingResult">
      {player.name} {player.role === Role.WEREWOLF ? "is" : "is not"} a Werewolf.
    </div>
  );
}

function doctor(game: Game) {
  const playerVote: Vote | null = voteForTurn(game.player, game.turnNumber);
  return (
    <div>
      {playerVote === null || !playerVote.confirmed ? (
        <VoteList
          game={game}
          votingPlayers={[game.player]}
          canBeVoted={game.players}
          showMatchingRoles={false}
          canVoteSelf
        />
      ) : (
        doctorResult(game.playersById.get(playerVote.playerVotedFor)!)
      )}
    </div>
  );
}

function doctorResult(player: Player) {
  return <div className="VotingResult">{player.name} is protected tonight.</div>;
}
