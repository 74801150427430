import { Button } from "@material-ui/core";
import React from "react";
import { Game, TurnType } from "./Game";
import GameSettings from "./GameSettings";
import "./Lobby.css";
import PlayerChip from "./PlayerChip";
import { sendRequestAndForget } from "./Utils";

type Props = {
  game: Game;
};

export default function Lobby(props: Props) {
  const game: Game = props.game;
  return (
    <div className="LobbyContent">
      <div className="LobbyReadyState">{lobbyReadyState(game)}</div>
      <div className="LobbyPlayerList">
        {Array.from(game.players.values()).map((player) => {
          return (
            <div key={player.id}>
              <PlayerChip player={player} />
            </div>
          );
        })}
      </div>
      <GameSettings
        settings={game.settings}
        gameId={game.id}
        host={game.host}
        numPlayers={game.players.length}
      />
    </div>
  );
}

function lobbyReadyState(game: Game) {
  if (game.turnType !== TurnType.LOBBY_READY_TO_START) {
    return <div>Waiting for more players ...</div>;
  }
  if (!game.host) {
    return <div>Waiting for host to start game ...</div>;
  }
  return (
    <Button variant="contained" color="primary" onClick={() => startGame(game)}>
      Start Game
    </Button>
  );
}

async function startGame(game: Game) {
  sendRequestAndForget(game.id, "start");
}
