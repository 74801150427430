import { Typography } from "@material-ui/core";
import React from "react";
import { Game, Player } from "./Game";
import "./GameOver.css";
import PlayerChip from "./PlayerChip";

type Props = {
  game: Game;
};

export default function GameOver(props: Props) {
  return (
    <div className="GameOverContainer">
      <div className="GameOverContent">
        <div className="GameOverTitle">
          <Typography variant="h4">Game Over</Typography>
        </div>
        <div className="GameOverPlayerListsContainer">
          <div className="GameOverPlayerList">
            <div>
              <Typography variant="h6">Winners:</Typography>
            </div>
            <div>
              {props.game.players
                .filter((p) => props.game.winningRoles.includes(p.role))
                .map((player) => (
                  <div key={player.id}>{gameOverPlayerChip(player)}</div>
                ))}
            </div>
          </div>
          <div className="GameOverPlayerList">
            <div>
              <Typography variant="h6">Losers:</Typography>
            </div>
            <div>
              {props.game.players
                .filter((p) => !props.game.winningRoles.includes(p.role))
                .map((player) => (
                  <div key={player.id}>{gameOverPlayerChip(player)}</div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function gameOverPlayerChip(player: Player) {
  return (
    <div>
      <PlayerChip player={player} showRole={true} fadeDeadPlayers={false} />
    </div>
  );
}
