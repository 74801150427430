import { Button, Dialog, DialogContent, LinearProgress, Typography } from "@material-ui/core";
import "fontsource-roboto";
import React from "react";
import { Game, TurnType } from "./Game";
import "./GameContent.css";
import GameOver from "./GameOver";
import Lobby from "./Lobby";
import Night from "./Night";
import PlayerChip from "./PlayerChip";
import ShowRole from "./ShowRole";
import VoteList from "./VoteList";

type GameContentProps = {
  gameId: string;
  playerId: string;
  millisUntilNextTurn: number;
  game: Game;
};

type State = {
  lastTurnNumberWithResultsAnnounced: number;
};

class GameContent extends React.Component<GameContentProps, State> {
  state: State = {
    lastTurnNumberWithResultsAnnounced: 0,
  };

  render() {
    if (!this.props.game.player.alive && this.props.game.turnType !== TurnType.END) {
      return GameContent.deadPlayer();
    }
    switch (this.props.game.turnType) {
      case TurnType.LOBBY:
      case TurnType.LOBBY_READY_TO_START:
        return <Lobby game={this.props.game} />;
      case TurnType.SHOW_ROLES:
        return (
          <ShowRole
            game={this.props.game}
            gameId={this.props.gameId}
            playerId={this.props.playerId}
          />
        );
      case TurnType.NIGHT:
        const nightRemaining =
          this.props.millisUntilNextTurn / (1000 * this.props.game.settings.nightLengthSecs);
        return (
          <div>
            <LinearProgress variant="determinate" value={nightRemaining * 100} />
            {this.night()}
          </div>
        );
      case TurnType.DAY:
        const dayRemaining =
          this.props.millisUntilNextTurn / (1000 * this.props.game.settings.dayLengthSecs);
        return (
          <div>
            <LinearProgress variant="determinate" value={dayRemaining * 100} />
            {this.day()}
          </div>
        );
      case TurnType.END:
        return <GameOver game={this.props.game} />;
      default:
        return <div>Turn: {this.props.game.turnType}</div>;
    }
  }

  private night() {
    const game: Game = this.props.game;
    return (
      <div>
        {game.deathsFromLastTurn && this.recentDeathsDialog()}
        <Night game={game} />
      </div>
    );
  }

  private day() {
    const game: Game = this.props.game;
    return (
      <div>
        {this.recentDeathsDialog()}
        <VoteList
          game={game}
          votingPlayers={game.players}
          canBeVoted={game.players.filter((p) => p.alive)}
          showMatchingRoles={false}
        />
      </div>
    );
  }

  private recentDeathsDialog() {
    const game: Game = this.props.game;
    const dialogText =
      game.turnType === TurnType.DAY
        ? game.deathsFromLastTurn.length > 0
          ? "Killed last night"
          : "Nobody died last night"
        : game.deathsFromLastTurn.length > 0
        ? "Killed yesterday"
        : "Nobody died yesterday";
    return (
      <Dialog
        open={this.state.lastTurnNumberWithResultsAnnounced < game.turnNumber}
        onClose={() =>
          this.setState({
            lastTurnNumberWithResultsAnnounced: game.turnNumber,
          })
        }
      >
        <DialogContent>
          <div className="RecentDeathsDialogContent">
            <Typography variant="h6">{dialogText}</Typography>
            <div>
              {game.deathsFromLastTurn.map((playerId) => {
                const player = game.playersById.get(playerId)!;
                return (
                  <div key={playerId}>
                    <PlayerChip player={player} fadeDeadPlayers={false} />
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  private static deadPlayer() {
    return (
      <div className="DeadMessageContainer">
        <div>
          <Typography variant="h6">You are dead</Typography>
        </div>
        <div className="DeadPlayerInstructions">
          Do not talk to any other players. You can quit the game below and continue to listen in,
          without talking.
        </div>
        <Button variant="contained" color="primary" href="/">
          Quit
        </Button>
      </div>
    );
  }
}

export default GameContent;
