import { Button } from "@material-ui/core";
import React, { FC } from "react";
import { Game, Player, Vote } from "./Game";
import PlayerChip from "./PlayerChip";
import { sendRequestAndForget, voteForTurn } from "./Utils";
import "./VoteList.css";

type Props = {
  game: Game;
  votingPlayers: Player[];
  canBeVoted: Player[];
  showMatchingRoles: boolean;
  canVoteSelf?: boolean;
};

const VoteList: FC<Props> = ({
  game,
  votingPlayers,
  canBeVoted,
  showMatchingRoles,
  canVoteSelf = false,
}) => {
  const playerVote: Vote | null = voteForTurn(game.player, game.turnNumber);
  const playerVotes: Vote[] = votingPlayers
    .map((w) => voteForTurn(w, game.turnNumber))
    .filter((v): v is Vote => v !== null);
  return (
    <div className="VoteListContainer">
      <div className="PlayerList">
        {game.players.map((player) => {
          const votingPlayers = playerVotes
            .filter((v) => v.playerVotedFor === player.id)
            .map((v) => v.votingPlayer)
            .map((p) => game.playersById.get(p)!);
          const canVote: boolean =
            canBeVoted.includes(player) &&
            (player !== game.player || canVoteSelf) &&
            (playerVote === null || !playerVote.confirmed);
          const showRole = showMatchingRoles && player.role === game.player.role;
          return (
            <div key={player.id} onClick={canVote ? () => vote(game, player.id) : undefined}>
              <PlayerChip player={player} showRole={showRole} votingPlayers={votingPlayers} />
            </div>
          );
        })}
      </div>
      <div>
        {playerVote !== null && !playerVote.confirmed && (
          <Button variant="contained" color="primary" onClick={() => confirmVote(game)}>
            Confirm: {game.playersById.get(playerVote.playerVotedFor)!.name}
          </Button>
        )}
      </div>
    </div>
  );
};

async function vote(game: Game, playerVotedFor: string) {
  sendRequestAndForget(
    game.id,
    "vote/" + game.turnNumber + "/" + game.player.id + "/" + playerVotedFor
  );
}

async function confirmVote(game: Game) {
  sendRequestAndForget(game.id, "confirmvote/" + game.turnNumber + "/" + game.player.id);
}

export default VoteList;
