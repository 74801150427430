import { BASE_URL } from "./Config";
import { Player, Vote } from "./Game";

const axios = require("axios").default;

export async function sendRequestAndForget(gameId: string, urlSuffix: string) {
  try {
    await axios.get(BASE_URL + "api/game/" + gameId + "/" + urlSuffix);
  } catch (error) {
    console.error(error);
  }
}

export function voteForTurn(player: Player, turn: number): Vote | null {
  if (player.votes.length > 0 && player.votes[player.votes.length - 1].turn === turn) {
    return player.votes[player.votes.length - 1];
  }
  return null;
}
