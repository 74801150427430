import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import JoinGame from "./JoinGame";
import Game from "./Game";

type MyState = {
  count: number; // like this
};

class App extends React.Component<{}, MyState> {
  state: MyState = {
    // optional second annotation for better type inference
    count: 0,
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/game/:gameId/:playerId" component={Game}></Route>
          <Route exact path="/">
            <JoinGame />
          </Route>
          <Route path="*">
            <this.noMatch />
          </Route>
        </Switch>
      </Router>
    );
  }

  noMatch() {
    return <div>404</div>;
  }
}

export default App;
