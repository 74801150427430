import { IconButton, Slider, Switch, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import "fontsource-roboto";
import React from "react";
import { BASE_URL } from "./Config";
import "./GameSettings.css";

const axios = require("axios").default;

type GameSettingsProps = {
  gameId: string;
  host: boolean;
  settings: Settings;
  numPlayers: number;
};

export type Settings = {
  nightLengthSecs: number;
  dayLengthSecs: number;
  numWerewolves: number;
  seer: boolean;
  doctor: boolean;
};

class GameSettings extends React.Component<GameSettingsProps, Settings> {
  state: Settings = this.props.settings;
  host: boolean = this.props.host;

  componentDidUpdate(prevProps: GameSettingsProps) {
    if (prevProps !== this.props) {
      this.forceUpdate();
      if (!this.props.host) {
        this.setState(this.props.settings);
      }
    }
  }

  render() {
    return (
      <div>
        <div className="SettingsHeading">
          <Typography variant="h6">Settings</Typography>
        </div>
        <div className="SettingsContent">
          <div className="SettingRow">
            <div className="SettingDescription">
              <div>
                <b>Day length</b>
              </div>
              <div>{this.state.dayLengthSecs} secs</div>
            </div>
            {this.host && (
              <div className="SettingSetter">
                <Slider
                  value={this.state.dayLengthSecs}
                  step={10}
                  min={30}
                  max={600}
                  valueLabelDisplay="off"
                  onChange={(event, value) => this.setState({ dayLengthSecs: value as number })}
                  onChangeCommitted={(event, value) =>
                    this.setState({ dayLengthSecs: value as number }, () => this.saveSettings())
                  }
                />
              </div>
            )}
          </div>
          <div className="SettingRow">
            <div className="SettingDescription">
              <div>
                <b>Night length</b>
              </div>
              <div>{this.state.nightLengthSecs} secs</div>
            </div>
            {this.host && (
              <div className="SettingSetter">
                <Slider
                  value={this.state.nightLengthSecs}
                  step={10}
                  min={20}
                  max={120}
                  valueLabelDisplay="off"
                  onChange={(event, value) => this.setState({ nightLengthSecs: value as number })}
                  onChangeCommitted={(event, value) =>
                    this.setState({ nightLengthSecs: value as number }, () => this.saveSettings())
                  }
                />
              </div>
            )}
          </div>
          <div className="SettingRow">
            <div className="SettingDescription">
              <b>Werewolves:</b>
            </div>
            <div className="SettingSetter">
              {this.host && (
                <IconButton
                  disabled={this.state.numWerewolves === 1}
                  onClick={() =>
                    this.setState({ numWerewolves: this.state.numWerewolves - 1 }, () =>
                      this.saveSettings()
                    )
                  }
                >
                  <Remove />
                </IconButton>
              )}
              <div className="NumWerewolvesCount">{this.state.numWerewolves}</div>
              {this.host && (
                <IconButton
                  disabled={
                    this.state.numWerewolves > this.numPlayersForChecks() / 2 - 2 ||
                    GameSettings.numAssignedRoles(this.state) === this.numPlayersForChecks()
                  }
                  onClick={() =>
                    this.setState({ numWerewolves: this.state.numWerewolves + 1 }, () =>
                      this.saveSettings()
                    )
                  }
                >
                  <Add />
                </IconButton>
              )}
            </div>
          </div>
          <div className="SettingRow">
            <div className="SettingDescription">
              <div className="ExtraRolesDescription">
                <b>Extra roles:</b>
              </div>
            </div>
            <div className="SettingSetter">
              <div>
                {this.roleSelector(
                  "Seer",
                  "1 guess/answer each night for who might be a werewolf",
                  this.state.seer,
                  () => this.setState({ seer: !this.state.seer }, () => this.saveSettings())
                )}
                {this.roleSelector(
                  "Doctor",
                  "Can protect one player (including self) each night from death",
                  this.state.doctor,
                  () => this.setState({ doctor: !this.state.doctor }, () => this.saveSettings())
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private roleSelector(roleName: string, roleDesc: string, enabled: boolean, onChange: () => void) {
    return (
      <div>
        <div className="RoleSelectorSwitch">
          <Switch
            checked={enabled}
            disabled={
              !enabled && GameSettings.numAssignedRoles(this.state) === this.numPlayersForChecks()
            }
            color="primary"
            onChange={() => {
              if (this.host) {
                onChange();
              }
            }}
          />
          <div>
            <Typography variant="subtitle2">{roleName}</Typography>
          </div>
        </div>
        <div className="RoleSelectorDescription">
          <Typography variant="caption">{roleDesc}</Typography>
        </div>
      </div>
    );
  }

  private numPlayersForChecks() {
    return Math.max(this.props.numPlayers, 6);
  }

  async saveSettings() {
    if (!this.host) {
      return;
    }
    try {
      await axios.post(BASE_URL + "api/game/" + this.props.gameId + "/settings", this.state);
    } catch (error) {
      console.error(error);
    }
  }

  private static numAssignedRoles(settings: Settings): number {
    let numAssignedRoles = settings.numWerewolves;
    if (settings.seer) {
      numAssignedRoles++;
    }
    if (settings.doctor) {
      numAssignedRoles++;
    }
    return numAssignedRoles;
  }
}

export default GameSettings;
