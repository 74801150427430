import { Box, Button, Container, TextField, Typography } from "@material-ui/core";
import "fontsource-roboto";
import React from "react";
import { BASE_URL } from "./Config";
import "./JoinGame.css";

const axios = require("axios").default;

type JoinGameState = {
  joiningGame: boolean;
  playerName: string;
  badPlayerName: boolean;
  gameId: string;
  badGameId: boolean;
  waiting: boolean;
};

class JoinGame extends React.Component<{}, JoinGameState> {
  state: JoinGameState = {
    joiningGame: false,
    playerName: "",
    badPlayerName: false,
    gameId: "",
    badGameId: false,
    waiting: false,
  };

  render() {
    return (
      <Box height="100%">
        <Container maxWidth="xs" className="Container">
          <div className="ContainerContent">
            <div>
              <Typography variant="h4">Werewolf</Typography>
            </div>
            <div className="TextInput">
              <TextField
                id="standard-basic"
                label="Name"
                error={this.state.badPlayerName}
                value={this.state.playerName}
                onChange={(event) => this.updatePlayerName(event)}
              />
            </div>
            {this.state.joiningGame && (
              <div className="TextInput">
                <TextField
                  id="standard-basic"
                  label="Game ID"
                  error={this.state.badGameId}
                  helperText={this.state.badGameId ? "Game not found" : null}
                  value={this.state.gameId}
                  onChange={(event) => this.updateGameId(event)}
                />
              </div>
            )}

            {!this.state.joiningGame && (
              <>
                <div className="ButtonContainer">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.waiting}
                    onClick={() => this.newGame()}
                  >
                    New Game
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ joiningGame: true });
                    }}
                  >
                    Join Game
                  </Button>
                </div>
              </>
            )}
            {this.state.joiningGame && (
              <div className="ButtonContainer">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.waiting}
                  onClick={() => this.joinGame()}
                >
                  Join
                </Button>
              </div>
            )}
          </div>
        </Container>
      </Box>
    );
  }

  updatePlayerName(event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({ playerName: event.currentTarget.value, badPlayerName: false });
  }

  updateGameId(event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({ gameId: event.currentTarget.value, badGameId: false });
  }

  async newGame() {
    try {
      const playerName = this.state.playerName.trim();
      if (!playerName) {
        this.setState({ badPlayerName: true });
        return;
      }
      this.setState({ waiting: true });
      const response = await axios.get(BASE_URL + "api/newgame/" + playerName);
      console.log(response.data);
      window.location.href = "/game/" + response.data.gameId + "/" + response.data.playerId;
    } catch (error) {
      console.error(error);
      this.setState({ waiting: false });
    }
  }

  async joinGame() {
    try {
      const gameId = this.state.gameId.trim();
      const playerName = this.state.playerName.trim();
      if (!playerName) {
        this.setState({ badPlayerName: true });
        return;
      }
      if (!gameId) {
        this.setState({ badGameId: true });
        return;
      }
      this.setState({ waiting: true });
      const response = await axios.get(BASE_URL + "api/joingame/" + gameId + "/" + playerName);
      console.log(response.data);
      window.location.href = "/game/" + response.data.gameId + "/" + response.data.playerId;
    } catch (error) {
      if (error.response.data.toLowerCase().includes("gameid")) {
        this.setState({ badGameId: true });
      } else {
        console.error(error);
      }
      this.setState({ waiting: false });
    }
  }
}

export default JoinGame;
